<template>
  <div class="inviteRegisterBox">
    <div class="inputBox">
      <Space :height="47"></Space>
      <van-field
        v-model="phone"
        type="number"
        :label-width="parseInt($pxToPxRatio(95), 10)"
        placeholder="请输入注册手机号"
        class="border"
        :error="!!phoneError"
        :error-message="phoneError"
      >
        <template #label>
          <div @click="$refs.selectCountry.init()">+{{ countryCode }}</div>
        </template>
      </van-field>
      <Space :height="29"></Space>
      <van-field v-model="code" type="number" placeholder="请输入短信验证码" class="border">
        <template #button>
          <SendCode url="/v1/auth/sendRegisterCode" :area-code="countryCode" :phone="phone"></SendCode>
        </template>
      </van-field>
      <Space :height="29"></Space>
      <van-field v-model="password" type="password" placeholder="请设置6-20位登录密码" class="border" />
      <Space :height="29"></Space>
      <van-field v-model="passwordAgain" type="password" placeholder="请再次确认登录密码" class="border" />
      <Space :height="29"></Space>
      <van-field
        type="text"
        label="邀请码"
        :value="inviteCode"
        class="codeInput"
        :label-width="parseInt($pxToPxRatio(106), 10)"
        readonly
      ></van-field>
      <Space :height="32"></Space>
    </div>
    <Space :height="30"></Space>
    <div class="stepBox">
      <div class="imgBox">
        <img src="@/assets/images/personal/invite/a4.png" alt="" />
      </div>
      <div class="textBox">
        <span>分享好友</span>
        <span>好友接受<br />邀请</span>
        <span>好友登录并<br />完成实名认证</span>
      </div>
    </div>
    <Space :height="30"> </Space>
    <div class="registerButton" @click="submit" :loading="$store.state.submitLoading">注册并下载APP</div>
    <Space :height="30"></Space>
    <div class="loginButton" @click="downloadApp">已有账号下载APP</div>
    <Space :height="54"></Space>
    <SelectCountry ref="selectCountry" :callback="countryCallback"></SelectCountry>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      phone: '',
      phoneError: '',
      code: '',
      password: '',
      passwordAgain: '',
      inviteCode: '',
      countryCode: 86,
    };
  },
  created() {
    this.judgeCode();
  },
  // mounted() {},
  methods: {
    countryCallback(res) {
      this.countryCode = res.code;
    },
    judgeCode() {
      let code = this.$route.query?.c;
      if (code) {
        this.inviteCode = code;
      } else {
        this.$router.replace('/account/register');
      }
    },
    submit: _.debounce(function() {
      if (!this.phone) {
        this.phoneError = '手机号不能为空';
        return;
      } else {
        this.phoneError = '';
      }
      if (this.countryCode === 86) {
        if (!/^1[3456789]\d{9}$/.test(this.phone)) {
          this.phoneError = '请输入正确手机号';
          return;
        }
      }
      if (!this.code) {
        this.$toast('验证码不能为空');
        return;
      }
      if (!this.password) {
        this.$toast('密码不能为空');
        return;
      }
      if (this.password !== this.passwordAgain) {
        this.$toast('输入的两次密码不一致');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/auth/register', {
        areaCode: this.countryCode,
        phone: this.phone,
        code: this.code,
        password: this.password,
        validatePassword: this.passwordAgain,
        inviteCode: this.inviteCode,
      })
        .then(res => {
          let that = this;
          this.$toast('注册成功');
          that.downloadApp();
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
    downloadApp() {
      this.$router.push('/other/download');
    },
  },
  // watch: {},
  computed: {
    // phoneProcess() {
    //   if (this.countryCode === 86) {
    //     return this.phone;
    //   } else {
    //     return this.countryCode + this.phone;
    //   }
    // },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.inviteRegisterBox {
  position: relative;
  padding: 387px 30px 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 720px;
    background: url('~@/assets/images/personal/invite/a3.png') no-repeat top left;
    background-size: cover;
    z-index: -1;
  }
  .inputBox {
    width: 690px;
    background: #ffffff;
    box-shadow: 0px 2px 21px 2px rgba(239, 212, 212, 0.39);
    border-radius: 18px;
    padding: 0 20px;
  }
  /deep/ .codeInput {
    background: #fff8f5;
    border: none;
    border-radius: 10px;
    &:after {
      display: none;
    }
    input {
      color: #773016;
    }
    .van-field__label {
      color: #773016;
    }
  }
  .stepBox {
    background: #ffffff;
    box-shadow: 0px 2px 21px 2px rgba(239, 212, 212, 0.39);
    border-radius: 18px;
    padding: 45px 30px;
    .imgBox {
      padding: 0 57px 0 50px;
    }
    .textBox {
      margin-top: 19px;
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      color: @title-color;
      padding-left: 20px;
      span {
        text-align: center;
        &:nth-child(2) {
          padding-left: 20px;
        }
      }
    }
  }
  .registerButton {
    height: 90px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #ffdb78 0%, #ff7f52 100%);
    border-radius: 10px;
    color: #773016;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 10px;
      z-index: -1;
      background: linear-gradient(90deg, #ffdb78 0%, #ff7f52 100%);
      opacity: 0.9;
      filter: blur(19px);
    }
  }
  .loginButton {
    height: 90px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #ff6533 0%, #ff4644 100%);
    border-radius: 10px;
    color: @white;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 10px;
      z-index: -1;
      background: linear-gradient(90deg, #ff6533 0%, #ff4644 100%);
      opacity: 0.8;
      filter: blur(19px);
    }
  }
}
</style>
